<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />
        </b-link>

        <div>
          <h2 class="text-center">Hey!</h2>

          <b-card-title class="mb-1 text-center"> {{ $t('Welcome to') }} Oscar Board! 👋</b-card-title>

          <div class="d-flex justify-content-center mb-2 mt-2" @click="$router.push('/login')">
            <b-button class="demo-inline-spacing rounded-lg" variant="primary"> Войти</b-button>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {BButton, BCard, BCardTitle, BLink} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {email, required} from '@validations'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {closeMainLoader} from '@/assets/scripts/scripts'

export default {
  components: {
    // BSV
    BButton,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
    }
  },
  created() {
    localStorage.token = this.$route.query.token
    this.verify()
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    this.closeMainLoader()
  },
  methods: {
    closeMainLoader,
    verify() {
      axios({
        method: 'get',
        // eslint-disable-next-line no-undef
        url: process.env.VUE_APP_API_URL + 'auth/verify-email/',
        params: {
          token: this.$route.query.token,
        },
      })
        .then(() => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Авторизация',
                icon: 'EditIcon',
                text: 'Авторизация завершена!',
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            }
          )
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.red-text {
  color: #ff5850;
}
</style>
